<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="拼团名称">
                <el-input v-model="search.groupbuy_name" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品名称">
                <el-input v-model="search.product_title" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品线">
                <el-select
                  v-model="search.product"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="团课" value="group"></el-option>
                  <el-option label="训练营" value="camp"></el-option>
                  <el-option label="活动" value="activity"></el-option>
                  <el-option label="商品" value="commodity"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" />
        <el-table-column prop="groupbuy_name" label="拼团名称" />
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" size="mini">正常</el-tag>
            <el-tag v-else-if="scope.row.state === 2" type="warning" size="mini"
              >禁用</el-tag
            >
            <el-tag v-else type="info" size="mini">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="product" label="产品线">
          <template slot-scope="scope">
            <span v-if="scope.row.product === 'group'">团课</span>
            <span v-else-if="scope.row.product === 'camp'">训练营</span>
            <span v-else-if="scope.row.product === 'activity'">活动</span>
            <span v-else-if="scope.row.product === 'commodity'">商品</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="product_title" label="产品名称" />
        <el-table-column prop="groupbuy_name" label="课程日期" width="60">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.timedata"
              placement="right"
              width="200"
              trigger="hover"
            >
              <el-table :data="scope.row.timedata">
                <el-table-column label="上课时间">
                  <template slot-scope="scope">
                    <span>{{ scope.row }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-link
                style="width: 100%; text-align: center"
                slot="reference"
                type="primary"
              >
                查看
              </el-link>
            </el-popover>
            <p v-else style="width: 100%; text-align: center">/</p>
          </template>
        </el-table-column>
        <el-table-column prop="min_price" label="最低价格" />
        <el-table-column prop="complete_sum" label="拼团成功数量" />
        <el-table-column prop="initiate_sum" label="发起数量" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="update_time" label="更新时间" />
        <el-table-column fixed="right" label="操作" width="280">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('marketing.groupbuy.getinfo')"
              @click="to_see_detail(scope.row.groupbuy_uuid)"
              type="primary"
              size="mini"
              >查看详情
            </el-button>
            <el-button
              v-if="is_auth('marketing.groupbuy.isdisable') && scope.row.state === 1"
              @click="
                operation_tip(
                  scope.row.groupbuy_uuid,
                  scope.row.groupbuy_name,
                  'isdisable'
                )
              "
              type="warning"
              size="mini"
              >禁用
            </el-button>
            <el-button
              v-if="is_auth('marketing.groupbuy.isenable') && scope.row.state === 2"
              @click="
                operation_tip(
                  scope.row.groupbuy_uuid,
                  scope.row.groupbuy_name,
                  'isenable'
                )
              "
              type="primary"
              size="mini"
              >启用
            </el-button>
            <el-button
              v-if="is_auth('marketing.groupbuy.getinitiatelist')"
              @click="to_initiatelist(scope.row.groupbuy_uuid)"
              type="primary"
              size="mini"
              >查看拼团
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "拼团活动",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {};
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "marketing.groupbuy.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 操作提示
    operation_tip(groupbuy_uuid = "", groupbuy_name = "", operation = "") {
      let tip = "";
      let options = {};
      // 启用
      if (operation === "isenable") {
        tip = "启用【" + groupbuy_name + "】？";
      }
      // 禁用
      if (operation === "isdisable") {
        tip = "禁用【" + groupbuy_name + "】？";
      }

      // 弹出二次确认
      this.$confirm(tip, "确认信息", options)
        .then(() => {
          this.isoperation(groupbuy_uuid, operation);
        })
        .catch(() => {});
    },
    // 操作
    isoperation(groupbuy_uuid = "", operation = "") {
      let postdata = {
        api_name: "marketing.groupbuy." + operation,
        token: this.Tool.get_l_cache("token"),
        groupbuy_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 查到拼团信息
    to_initiatelist(groupbuy_uuid) {
      this.$router.push({
        path: "/marketing/groupbuy/initiatelist",
        query: { groupbuy_uuid },
      });
    },
    // 查看拼团详情
    to_see_detail(groupbuy_uuid) {
      this.$router.push({ path: "/marketing/groupbuy/detail", query: { groupbuy_uuid } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.el-table .cell {
  display: flex;
}

.form-tag_colour-div {
  min-height: 200px;
}

.open-info {
  width: 100%;
  text-align: center;
}

.open-info-img {
  width: 200px;
  margin: 50px auto;
}

.open-info-img img {
  width: 100%;
}

.open-info p {
  color: #999;
  margin-top: 60px;
  font-size: 14px;
}

.open-btn {
  margin-top: 20vh;
  text-align: center;
  margin-bottom: 10vh;
}

.agree-view {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
}

.agree-btn {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
